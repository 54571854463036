<template>
  <div v-if="composePractice && composePractice.id > 0">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'SatManagement' }">
            View
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ `${$t("sat.Full Tests")} - ${PageTitle}` }}
          （<b v-if="composePractice.exam.is_public === 1">Published</b>
          <b v-else>Drafts</b>）
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="text-right">
      <div class="title-box-button">
        <template v-if="isEdit">
          <span v-if="this.id && Number(this.id) > 0">
            <el-tooltip
              class="item ml-2"
              effect="dark"
              content="Cancel"
              placement="top"
            >
              <router-link
                :to="{
                  name: $route.name,
                  params: {
                    ...$route.params
                  },
                  query: {
                    ...$route.query,
                    isEdit: 0
                  }
                }"
              >
                <el-button type="primary" size="small">
                  Save
                  <i class="fas fa-save"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </span>
        </template>
        <template v-else>
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="Edit"
            placement="top"
          >
            <router-link
              :to="{
                name: $route.name,
                params: {
                  ...$route.params
                },
                query: {
                  ...$route.query,
                  isEdit: 1
                }
              }"
            >
              <el-button type="success" size="small">
                Edit
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip
            v-show="composePractice.exam.is_public === 1"
            class="ml-2"
            effect="dark"
            content="Change to draft"
            placement="top"
          >
            <el-button
              type="danger"
              size="small"
              @click="updateInfo({ is_public: 0 })"
            >
              Change to draft
              <i class="fas fa-level-down-alt"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="composePractice.scorable_id && composePractice.scorable_id > 0"
            v-show="composePractice.exam.is_public === 0"
            class="ml-2"
            effect="dark"
            content="Publish"
            placement="top"
          >
            <el-button
              type="success"
              size="small"
              @click="updateInfo({ is_public: 1 })"
            >
              Publish
              <i class="fas fa-upload"></i>
            </el-button>
          </el-tooltip>
        </template>
      </div>
    </div>
    <hr />
    <el-form :model="composePractice" label-width="180px">
      <el-form-item>
        <div slot="label">
          Name
        </div>
        <div>
          <el-input
            v-model="composePractice.exam.title"
            :disabled="!isEdit"
            @change="updateInfo({ title: composePractice.exam.title })"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item>
        <div slot="label">
          Score Table
        </div>
        <div>
          <el-tooltip
            v-if="
              composePractice.scorable_id && composePractice.scorable_id > 0
            "
            effect="dark"
            content="View"
            placement="top"
          >
            <router-link
              :to="{
                name: 'ScoreTable',
                params: {
                  type: 'composePractice',
                  practiceId: id,
                  id: composePractice.scorable_id
                }
              }"
            >
              <el-button type="success" size="small">
                <i class="fas fa-eye"></i>
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip
            v-else
            effect="dark"
            content="No Score Table"
            placement="top"
          >
            <router-link
              :to="{
                name: 'CreateScoreTable',
                params: {
                  type: 'composePractice',
                  practiceId: id
                }
              }"
            >
              <el-button type="danger">
                <i class="fas fa-exclamation-triangle"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item>
        <div slot="label">
          Subjects
        </div>
        <div>
          <el-alert type="success" :closable="false" class="mb-3">
            <h6 class="m-2">
              Subjects:
              {{ composePractice.compose_practice_practices.length }}
            </h6>
          </el-alert>
          <el-alert
            v-if="hasTaken && isEdit"
            class="mb-4 p-3"
            title="This Test has been tested by students. Only basic information can be edited, and the number and order of units cannot be changed."
            type="warning"
            show-icon
            :closable="false"
          >
          </el-alert>
          <el-alert
            v-else
            class="mb-4 p-3"
            title="Drag and drop Modules to sort them."
            type="info"
            show-icon
            :closable="false"
          >
          </el-alert>
        </div>
        <div v-if="composePractice.compose_practice_practices.length > 0">
          <draggable
            :list="composePractice.compose_practice_practices"
            :class="isEdit && !hasTaken ? 'question-card' : ''"
            :disabled="!isEdit || hasTaken"
            @change="setSectionOrder"
          >
            <div
              v-for="(practice,
              index) in composePractice.compose_practice_practices"
              :key="practice.id"
            >
              <PracticeCard
                :practice="practice"
                :order="index + 1"
                :isEdit="isEdit"
                :composePracticeId="composePractice.id"
                :removeDisabled="!isEdit || hasTaken"
                @removeSection="removeSection"
              ></PracticeCard>
            </div>
          </draggable>
        </div>
        <el-empty v-else description="No Data" v-show="!isEdit"></el-empty>
        <template>
          <div v-if="isEdit">
            <el-button
              type="success"
              style="width: 100%;"
              @click="showCreateNewSectionDialog = true"
            >
              Add <i class="fa fa-plus"></i>
            </el-button>
          </div>
        </template>
      </el-form-item>
    </el-form>
    <el-dialog
      class="add-passage-dialog"
      :visible.sync="showCreateNewSectionDialog"
      :close-on-click-modal="false"
      width="60%"
      top="2vh"
    >
      <div slot="title">
        Add Module:
        <kbd>{{ composePractice.compose_practice_practices.length + 1 }}</kbd>
      </div>
      <template>
        <div class="questions-list">
          <el-form
            :model="newTest"
            ref="form"
            label-width="100px"
            :rules="rules"
          >
            <el-form-item :label="$t('AddSubject.Method')">
              <el-radio-group v-model="addMode">
                <el-radio-button :label="1">
                  {{ $t("AddSubject.Choose from an existing subject") }}
                </el-radio-button>
                <el-radio-button :label="0">
                  {{ $t("AddSubject.Create a new module") }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <template v-if="addMode === 0">
              <el-form-item label="Subject" required prop="subject">
                <el-radio-group v-model="newTest.subject">
                  <el-radio-button
                    v-for="subject in subjectsNow"
                    :key="subject.id"
                    :label="subject.id"
                  >
                    {{ titleCase(subject.name) }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Name" required prop="title">
                <el-input
                  v-model="newTest.title"
                  placeholder="Name"
                  maxlength="100"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="success"
                  style=""
                  @click="addNewPracticeToFull"
                >
                  Create
                </el-button>
              </el-form-item>
            </template>
          </el-form>
          <div v-if="addMode === 1">
            <hr />
            <div class="text-center" style="margin:15px 0">
              <el-pagination
                background
                @current-change="setPage"
                :page-size="pageSize"
                :current-page="page"
                :total="resultsTotal"
                :pager-count="9"
                layout="prev, pager, next"
              >
              </el-pagination>
            </div>
            <el-input
              class="mb-3"
              :placeholder="$t('AddSubject.Type keyword here')"
              v-model="keyWord"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchPractices"
              >
                Search
              </el-button>
            </el-input>
            <div class="table-box">
              <el-table :data="practices" border :key="isUpdate" center>
                <el-table-column prop="title" label="Module Name">
                  <template slot-scope="scope">
                    <router-link
                      style="padding: 3px 0"
                      :to="{
                        name: 'SatPracticeDetail',
                        query: { id: scope.row.id }
                      }"
                    >
                      <i class="fas fa-eye" />
                      <b> {{ scope.row.exam.title }}</b>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column prop="content" label="Questions" width="100">
                  <template slot-scope="scope">
                    <b>{{ scope.row.exam.exam_questions_count }}</b>
                  </template>
                </el-table-column>
                <el-table-column label="Action" width="120">
                  <template slot-scope="scope">
                    <el-button v-show="false"></el-button>
                    <el-button v-show="scope.row.isIn" size="small" disabled>
                      Added
                    </el-button>
                    <el-button
                      v-show="!scope.row.isIn"
                      @click="addPassage(scope.$index)"
                      type="success"
                      size="small"
                    >
                      <i class="fa fa-plus"></i> Add
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="text-center" style="margin:15px 0">
              <el-pagination
                background
                @current-change="setPage"
                :page-size="pageSize"
                :current-page="page"
                :total="resultsTotal"
                :pager-count="9"
                layout="prev, pager, next"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import PracticeCard from "@/views/satComposePractice/components/Practice.vue";
import draggable from "vuedraggable";
import { instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, PracticeCard, draggable },

  mixins: [Common],

  props: [],
  data() {
    return {
      isUpdate: false,
      addMode: 1,
      showCreateNewSectionDialog: false,
      breakTime: [],
      isEdit: false,
      composePractice: {
        test_id: null,
        test_type: "",
        exam: {
          title: null
        },
        compose_practice_practices: []
      },
      newTest: {
        isNew: null,
        type: "practice",
        subject: null,
        title: ""
      },
      practices: [],
      subjects: [],
      subject_id: null,
      keyWord: "",
      pageSize: 0,
      page: 0,
      resultsTotal: 0
    };
  },
  computed: {
    hasTaken() {
      return (
        this.composePractice.exam.user_exams_count &&
        this.composePractice.exam.user_exams_count > 0
      );
    },
    rules() {
      return {
        subject: [
          {
            required: true
          }
        ],
        title: [
          {
            required: true
          }
        ]
      };
    },
    subjectsNow() {
      let subjects = [];
      if (this.newTest.isNew === 1) {
        subjects = [this.subjects[4], this.subjects[2]];
      } else {
        subjects = [
          this.subjects[0],
          this.subjects[1],
          this.subjects[2],
          this.subjects[3]
        ];
      }
      return subjects;
    },
    PageTitle() {
      let title = this.composePractice.exam.title
        ? this.composePractice.exam.title
        : "Edit";
      return title;
    },
    instant() {
      return instant;
    },
    id() {
      return this.$route.query.id;
    }
  },
  watch: {},

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    await this.getPractices();
    if (this.id && Number(this.id) > 0) {
      await this.getComposePracticeDetail(0);
      if (this.$route.query.isEdit) {
        this.isEdit = Number(this.$route.query.isEdit);
      }
      this.checkSections();
    } else {
      this.isEdit = true;
    }
  },

  methods: {
    async checkSections() {
      this.practices.forEach(practice => {
        practice["isIn"] = false;
      });
      this.composePractice.compose_practice_practices.forEach(section => {
        this.practices.forEach(practice => {
          if (section.id === practice.id) {
            practice["isIn"] = true;
          }
        });
      });
      this.isUpdate = !this.isUpdate;
    },
    async updateInfo(data) {
      await SAT.updateComposePractice(this.id, {
        compose_practice: {
          exam: {
            title: this.composePractice.exam.title,
            ...data
          }
        }
      });
      await this.getComposePracticeDetail();
    },
    async addNewPracticeToFull() {
      try {
        let valid = await this.$refs.form.validate();

        if (!valid) {
          return false;
        }
      } catch (e) {
        return e;
      }
      const res = await SAT.createPractice({
        practice: {
          exam: {
            title: this.newTest.title,
            is_public: 0
          },
          subject_type: "Modules\\SAT\\Entities\\SatSubject",
          subject_id: this.newTest.subject,
          is_new: this.newTest.isNew
        }
      });
      await this.addSectionWithId(res.practice.id);
      this.showCreateNewSectionDialog = false;
    },
    async setSectionOrder() {
      let arr = [];
      this.composePractice.compose_practice_practices.forEach((item, index) => {
        arr.push({
          practice_id: item.id,
          order: index + 1,
          break_time: 0
        });
      });
      await SAT.updateComposePracticePractices(this.id, {
        compose_practice_practices: arr
      });
      this.getComposePracticeDetail();
    },
    async addSectionWithId(id) {
      let arr = [];
      this.composePractice.compose_practice_practices.forEach((item, index) => {
        arr.push({
          practice_id: item.id,
          order: index + 1,
          break_time: 0
        });
      });

      arr.push({
        practice_id: id,
        order: arr.length + 1,
        break_time: 0
      });
      await SAT.updateComposePracticePractices(this.id, {
        compose_practice_practices: arr
      });
      this.showCreateNewSectionDialog = false;
      await this.getComposePracticeDetail();
      this.newTest.title = "";
      setTimeout(() => {
        this.showCreateNewSectionDialog = true;
      }, 500);
    },
    async removeSection(order) {
      let arr = [];
      this.composePractice.compose_practice_practices.forEach((item, index) => {
        if (order !== index + 1) {
          arr.push({
            practice_id: item.id,
            order: index + 1,
            break_time: 0
          });
        }
      });
      await SAT.updateComposePracticePractices(this.id, {
        compose_practice_practices: arr
      });
      await this.getComposePracticeDetail();
      await this.checkSections();
    },
    async addPassage(index) {
      await this.addSectionWithId(this.practices[index].id);
      // this.showCreateNewSectionDialog = false;
      await this.checkSections();
    },
    searchPractices() {
      this.page = 1;
      this.getPractices();
    },
    async getPractices() {
      this.practices = [];
      const res = await SAT.getPractices({
        page: this.page,
        keyword: this.keyWord,
        // subject_id: this.subject_id
        categories: [13, 14]
      });
      this.practices = res.practices.data;
      this.page = res.practices.current_page;
      this.resultsTotal = res.practices.total;
      this.pageSize = res.practices.per_page;
      this.checkSections();
    },
    searchPassages() {
      this.getPractices();
    },
    setPage(page) {
      this.page = page;
      this.getPractices();
    },
    async getComposePracticeDetail(type = 1) {
      if (this.isEdit && type === 1) {
        this.$notify({
          title: "Automatic saving successful!",
          type: "success"
        });
      }
      const res = await SAT.getComposePracticeDetail(this.id);
      this.composePractice = {
        ...res.compose_practice,
        compose_practice_practices: res.compose_practice.practices
      };
      if (this.composePractice.marks.length > 0 && this.composePractice.marks[0].name.en === "new") {
        this.newTest.isNew = 1;
      }
    }
  }
};
</script>

<style scoped>
.title-box {
  display: flex;
  align-items: center;
}
.title-box .title-box-input {
  width: 50%;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}
.questions-list {
  padding: 1rem;
  /* border: 2px solid var(--themeColor); */
}
.table-box {
  height: 500px;
  overflow: auto;
}
</style>
